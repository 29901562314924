import ImageOpt from './ImageOpt';
import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Swiper, SwiperSlide } from 'swiper/react';

const CardListing = ({ module }) => {
  const handleNextClick = (selector) => {
    const swiper = document.querySelector(selector).swiper;
    swiper.slideNext();
  };
  const handlePrevClick = (selector) => {
    const swiper = document.querySelector(selector).swiper;
    swiper.slidePrev();
  };

  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const sectionShow = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const animFade = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeInOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  if (module.hideSection === 'no') {
    if (module.contentStyle === 'after') {
      return (
        <motion.section
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={sectionShow}
          className={`content-card py-70 lgscreen:py-35${
            module.extraClass ? ` ${module.extraClass}` : ''
          }`}
          id={module.extraId}
        >
          <motion.div
            className="container-fluid"
            ref={ref}
            initial="hidden"
            animate={isInView || hasPlayed ? 'show' : 'hidden'}
            variants={sectionShow}
          >
            <div className="flex flex-wrap -mx-15 lgscreen:mx-0 gap-y-8">
              {module.cards.map((item, i) => (
                <div className="lg:w-4/12 w-full px-15 lgscreen:px-0" key={i}>
                  <motion.div variants={animFade} className="content-card-bx">
                    {item?.image && (
                      <div className="img">
                        <ImageOpt
                          imgData={item.image.mediaItemUrl}
                          imgAlt={item.image.altText}
                          width={410}
                          height={250}
                          imgLoad="lazy"
                        />
                      </div>
                    )}
                    <div className="content-card-inner pt-30">
                      {item?.title && (
                        <motion.div variants={animFade} className="title-black">
                          <h6 className="!text-red-200">{item.title}</h6>
                        </motion.div>
                      )}
                      <motion.div variants={animFade} className="content pt-10">
                        <p>{parse(item.description)}</p>
                        {item?.includes && <p>{parse(item?.includes)}</p>}
                      </motion.div>
                      <div className="btn-custom mt-40 lgscreen:mt-20 flex flex-wrap gap-x-4 gap-y-4">
                        {item?.button && (
                          <motion.div variants={animFade}>
                            <Link
                              to={item.button.url}
                              className="btn btn-green"
                              target={item.button.target}
                            >
                              {item.button.title}
                            </Link>
                          </motion.div>
                        )}
                        {item?.enquiry && (
                          <motion.div variants={animFade}>
                            <Link
                              to={item.enquiry.url}
                              className="btn btn-border"
                              target={item.enquiry.target}
                            >
                              {item.enquiry.title}
                            </Link>
                          </motion.div>
                        )}
                      </div>
                    </div>
                  </motion.div>
                </div>
              ))}
            </div>
          </motion.div>
        </motion.section>
      );
    } else {
      return (
        <motion.section
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={sectionShow}
          className={`story-cards relative py-130 lgscreen:py-30 ${
            module.background === 'yes' ? 'add-pattern' : ''
          } ${module.extraClass ? ` ${module.extraClass}` : ''}`}
          id={`${module.extraId ? `${module.extraId}` : ''}`}
        >
          <motion.div
            className="container-fluid relative"
            ref={ref}
            initial="hidden"
            animate={isInView || hasPlayed ? 'show' : 'hidden'}
            variants={sectionShow}
          >
            <div className="w-[834px] lgscreen:w-full lgscreen:px-20 mx-auto text-left px-20">
              {module?.preHeading && (
                <motion.div variants={animFade} className="preheading mb-10">
                  <span className="text-gray-100 text-14 font-300 opacity-80 leading-26 uppercase">
                    {module.preHeading}
                  </span>
                </motion.div>
              )}
              {module?.heading && (
                <motion.div variants={animFade} className="title-green">
                  <h3>
                    {module.heading}&nbsp;
                    {module?.subHeading && <span>{module.subHeading}</span>}
                  </h3>
                </motion.div>
              )}
              {module?.description && (
                <motion.div variants={animFade} className="content pt-15">
                  <p>{parse(module.description)}</p>
                </motion.div>
              )}
            </div>
            <div className="story-card-slider block lgscreen:hidden relative z-9 mt-50 mdscreen:mt-20">
              <div className="flex flex-wrap -mx-15 lgscreen:mx-0">
                {module.cards.map((item, i) => (
                  <div className="lg:w-4/12 w-full px-15 lgscreen:px-0" key={i}>
                    <motion.div variants={animFade} className="relative">
                      <div className="img relative">
                        {item?.image && (
                          <ImageOpt
                            imgData={item.image.mediaItemUrl}
                            imgAlt={item.image.altText}
                            imgClass="w-full rounded-4 object-cover"
                            width={500}
                            height={500}
                            imgLoad="lazy"
                          />
                        )}
                      </div>
                      <div className="absolute bottom-50 left-50per translate-x-minus_50 text-left max-w-[315px] w-full mx-auto">
                        {item?.title && (
                          <motion.div variants={animFade}>
                            <h4 className="text-lighttan text-36 leading-[41px]">
                              {item.title}
                            </h4>
                          </motion.div>
                        )}
                        {item?.description && (
                          <motion.div
                            variants={animFade}
                            className="content white pt-15"
                          >
                            <p>{parse(item.description)}</p>
                          </motion.div>
                        )}
                      </div>
                    </motion.div>
                  </div>
                ))}
              </div>
            </div>
            <div className="story-card-slider hidden lgscreen:block relative z-9 mt-50 mdscreen:mt-20">
              <Swiper
                spaceBetween={30}
                loop={true}
                slidesPerView={'3'}
                speed={1000}
                pagination={{
                  clickable: false,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  641: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                modules={[Autoplay, Navigation]}
              >
                {module.cards.map((item, i) => (
                  <SwiperSlide key={i}>
                    <div className="relative">
                      <div className="img relative">
                        {item?.image && (
                          <ImageOpt
                            imgData={item.image.mediaItemUrl}
                            imgAlt={item.image.altText}
                            imgClass="w-full rounded-4 object-cover"
                            width={500}
                            height={500}
                            imgLoad="lazy"
                          />
                        )}
                      </div>
                      <div className="absolute bottom-50 left-50per translate-x-minus_50 text-left max-w-[315px] w-full mx-auto">
                        {item?.title && (
                          <h4 className="text-lighttan text-36 leading-[41px]">
                            {item.title}
                          </h4>
                        )}
                        {item?.description && (
                          <div className="content white pt-15">
                            <p>{item.description}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-arrow flex gap-x-5 hidden lgscreen:block">
                <button
                  className="absolute -left-80 lgscreen:left-20 top-[calc(50%_-_15px)] translate-y-minus_50 z-9 transition-all duration-300 outline-none.  "
                  onClick={() => handlePrevClick('.story-card-slider .swiper')}
                >
                  <svg width="50" height="40" viewBox="0 0 74 69" fill="none">
                    <g opacity="0.9" filter="url(#filter0_d_96_249)">
                      <path
                        className="transition-all duration-300"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M35.6991 60.9999C27.243 60.9668 20.3684 55.1611 14.9832 48.6198C9.44714 41.8955 4.75371 33.8961 6.32274 25.3177C7.96122 16.3596 14.4917 8.81415 22.8942 5.36731C30.7665 2.13796 39.1502 5.19738 46.922 8.66318C55.1338 12.3252 64.6465 16.1687 66.7642 24.931C68.9003 33.7695 62.545 41.9011 56.6183 48.7825C51.0681 55.2266 44.1878 61.033 35.6991 60.9999Z"
                        fill="#AA9F87"
                      />
                    </g>
                    <path
                      d="M40.7725 41.5366L31.0408 32.5L40.7725 23.4634"
                      stroke="#F2EFE8"
                    />
                    <defs>
                      <filter
                        id="filter0_d_96_249"
                        x="0.0161133"
                        y="0"
                        width="73.1709"
                        height="69"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="3" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_96_249"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_96_249"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </button>
                <button
                  className="absolute -right-80 lgscreen:right-20 top-[calc(50%_-_15px)] translate-y-minus_50 z-9 transition-all duration-300 outline-none.  "
                  onClick={() => handleNextClick('.story-card-slider .swiper')}
                >
                  <svg width="50" height="40" viewBox="0 0 74 69" fill="none">
                    <g opacity="0.9" filter="url(#filter0_d_96_246)">
                      <path
                        className="transition-all duration-300"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M37.4884 4.00014C45.9445 4.03317 52.8191 9.83894 58.2043 16.3802C63.7404 23.1045 68.4338 31.1039 66.8648 39.6823C65.2263 48.6404 58.6958 56.1859 50.2933 59.6327C42.421 62.862 34.0373 59.8026 26.2655 56.3368C18.0537 52.6748 8.54102 48.8313 6.42331 40.069C4.28717 31.2305 10.6425 23.0989 16.5692 16.2175C22.1194 9.77336 28.9997 3.96699 37.4884 4.00014Z"
                        fill="#AA9F87"
                      />
                    </g>
                    <path
                      d="M32.415 23.4634L42.1467 32.5L32.415 41.5366"
                      stroke="#F2EFE8"
                    />
                    <defs>
                      <filter
                        id="filter0_d_96_246"
                        x="0.000488281"
                        y="0"
                        width="73.1709"
                        height="69"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="3" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_96_246"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_96_246"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </motion.div>
        </motion.section>
      );
    }
  }
};

export default CardListing;
export const TravelStyleSingleCardListingFragment = graphql`
  fragment TravelStyleSingleCardListingFragment on WpTravel_Travelstylecontent_TravelStyle_CardListing {
    extraId
    extraClass
    hideSection
    contentStyle
    background
    preHeading
    heading
    subHeading
    description
    cards {
      title
      description
      button {
        target
        title
        url
      }
      includes
      image {
        altText
        mediaItemUrl
      }
    }
  }
`;
