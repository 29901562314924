import { graphql } from "gatsby";
import React, { useEffect } from "react";
import PageLayout from "../components/Layout/Page";
import Seo from "../components/Seo";
import TravelStyle from "../modules/TravelStyle";

const TravelStyleTemplate = (props) => {
  const modules = props?.data?.travelStyle?.travelStyleContent?.travelStyle || [];

  useEffect(() => {
    document.body.classList.remove("nav-menu-open");
    document.body.classList.remove("header-sticky");
    document.body.classList.remove("video-modal-open");
    document.body.classList.remove("blog-single-custom");

  }, []);

  return (
    <PageLayout>
      <Seo seo={props.data.travelStyle.seo} />
      <TravelStyle modules={modules} location={props.location} />
    </PageLayout>
  )
}

export default TravelStyleTemplate;
export const TravelStyleById = graphql`
  query travelStyleById ($id: String) {
    travelStyle: wpTravel(id: { eq: $id }) {
      id
      ...seoTravelStyleFragment
      ...TravelStyleModulesFragment
    }
  }
`;