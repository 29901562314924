
import { graphql } from "gatsby";
import React from "react";

import Banner from "../components/Banner"
import SimpleContent from "../components/SimpleContent"
import RelatedTripInspiration from "../components/RelatedTripInspiration"
import RelatedBlog from "../components/RelatedBlog"
import PopularDestination from "../components/PopularDestination"
import GuestFeedbacks from "../components/GuestFeedbacks"
import ImageWithContent from "../components/ImageWithContent"
import CardListing from "../components/CardListing"

const TravelStyle = ({ modules, location }) => {
  const components = {
    Banner,
    SimpleContent,
    RelatedTripInspiration,
    RelatedBlog,
    PopularDestination,
    GuestFeedbacks,
    ImageWithContent,
    CardListing,
  }

  return (
    <>
      {modules
        ? modules
            .filter(
              (module) => !module?.hideSection || module?.hideSection === 'no'
            )
            .map((module, i) => {
              const moduleName = module.__typename.replace(
                'WpTravel_Travelstylecontent_TravelStyle_',
                ''
              );
              return (
                components[moduleName] &&
                React.createElement(components[moduleName], {
                  key: i,
                  module,
                  location,
                })
              );
            })
        : null}
    </>
  );
};

export default TravelStyle;
export const TravelStyleModulesFragment = graphql`
  fragment TravelStyleModulesFragment on WpTravel {
    travelStyleContent {
      travelStyle {
        __typename
        ...TravelStyleBannerFragment
        ...TravelStyleSingleSimpleContentFragment
        ...TravelStyleSingleRelatedTripInspirationFragment
        ...TravelStyleSingleRelatedBlogFragment
        ...TravelStyleSinglePopularDestinationFragment
        ...TravelStyleSingleGuestFeedbacksFragment
        ...TravelStyleImageWithContentFragment
        ...TravelStyleSingleCardListingFragment
      }
    }
  }
`;
